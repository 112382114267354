import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Gradient from "../components/common/Gradient";
import ContactModal from "../components/ContactModal";
import Button from "../components/common/Button";
import IntegrationGrid from "../components/IntegrationGrid";

const IntegrationsPage = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Helmet>
        <title>Smart Hotel Integrations | Kohost</title>
      </Helmet>
      <Layout>
        <section className="max-w-6xl container mx-auto mt-16 mb-32 px-6">
          <h1 className="text-6xl font-bold mb-8 text-center">
            <Gradient>Orchestrate</Gradient> your systems to create a world
            class <Gradient reverse>guest experience.</Gradient>
          </h1>
          <p className="text-center text-lg max-w-3xl mx-auto mb-8">
            Remove the complexity of coordinating complex systems. Unleash the
            unlocked power of systems you already own.
          </p>
          <p className="text-center">
            <Button onClick={() => setOpenModal(true)}>
              Become a Partner →
            </Button>
          </p>
        </section>

        <section className="container max-w-7xl mx-auto flex flex-col lg:flex-row mb-20">
          <div className="flex-1 mb-4 lg:mb-0">
            <p className="font-bold text-center">Design Better Systems</p>
          </div>
          <div className="flex-1 mb-4 lg:mb-0">
            <p className="font-bold text-center">
              Lower integration costs and complexity
            </p>
          </div>
          <div className="flex-1 mb-4 lg:mb-0">
            <p className="font-bold text-center">Increase Revpar</p>
          </div>
          <div className="flex-1 mb-4 lg:mb-0">
            <p className="font-bold text-center">
              Save time and handle guest requests in minutes
            </p>
          </div>
        </section>

        <section className="container max-w-7xl mx-auto mb-20 px-4">
          <div className="flex justify-center">
            <StaticImage
              src="../images/soc2.png"
              className="w-72 mx-auto "
              alt="Soc II Type II"
              jpgOptions={{ progressive: true }}
            />
          </div>
          <h2 className="font-semibold text-center text-xl">
            Kohost is proud to display a <Gradient>SOC 2 Type 2</Gradient>{" "}
            certification.
          </h2>
        </section>

        <section className="max-w-3xl container mx-auto mt-16 mb-32 px-6">
          <h2 className="font-bold text-center text-4xl mb-8">
            Join and plug into the Kohost guest experience, together we can
            remove technology hurdles for hoteliers.
          </h2>
          <p className="text-center">
            <Button onClick={() => setOpenModal(true)}>Save me time</Button>
          </p>
        </section>

        <section className="max-w-6xl container mx-auto mt-16 mb-32 px-6">
          <h3 className="font-bold text-center text-3xl mb-16">
            Trusted by the <Gradient>best partners</Gradient>
          </h3>

          <IntegrationGrid />
        </section>

        <section className="container mx-auto px-4">
          <h2 className="font-bold text-center text-4xl mb-8">
            Partner with us
          </h2>

          <p className="text-center">
            <Button variant="tertiary" onClick={() => setOpenModal(true)}>
              Grow my client base →
            </Button>
          </p>
        </section>

        <ContactModal open={openModal} onClose={() => setOpenModal(false)} />
      </Layout>
    </>
  );
};

export default IntegrationsPage;
